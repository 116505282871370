
/* ==========================================================================
   Config
   ========================================================================== */

// General configurations
@import 'common/settings/config';

// Color definitions
@import 'common/settings/colors';


/* ==========================================================================
   Saas Tools (imported from node_modules)
   ========================================================================== */

// Setting for Saas Tool 'Scut: Sass-CSS Utitilies'
@import 'common/settings/scut';

// Scut: Sass-CSS Utitilies (https://davidtheclark.github.io/scut/)
@import 'scut/dist/scut';


// Setting for Saas Tool 'Media Queries with superpowers'
@import 'common/settings/breakpoints';

// Media Queries with superpowers (https://github.com/sass-mq/sass-mq)
@import 'sass-mq/mq';

// Susy is a lightweight grid-layout engine for Sass (http://oddbird.net/susy/)
//@import 'susy/sass/susy';


/* ==========================================================================
   Sizes
   ========================================================================== */

@import 'common/settings/sizes/font-sizes';
@import 'common/settings/sizes/image-sizes';
@import 'common/settings/sizes/padding-sizes';
@import 'common/settings/sizes/width-sizes';


/* ==========================================================================
   Base
   ========================================================================== */

* {
	background: transparent !important;
	color: #000 !important;
	box-shadow: none !important;
	text-shadow: none !important;
}

a,
a:visited {
	text-decoration: underline;
}

.ir a:after,
a[href^="javascript:"]:after,
a[href]:after {
	content: none !important;
 }

pre,
blockquote {
	border: none;
	page-break-inside: avoid;
}

thead {
	display: table-header-group;
}

tr,
img {
	page-break-inside: avoid;
}

img {
	max-width: 100% !important;
}

@page {
	margin: 0.5cm;
}

p,
h2,
h3 {
	orphans: 3;
	widows: 3;
}

h2,
h3 {
	break-after:avoid;
	page-break-after: avoid;
}


/* ==========================================================================
   Font-Sizes
   ========================================================================== */

html,
body {
	width: 100%;
	height: auto;
	color: #000;
	/*overflow: auto;*/
}

body {
	font-size:14px;
}

h1, .c-h1 {
	font-size: 24px;
}

h2, .c-h2 {
	font-size: $contentTextSizeMobile;
}

h3, .c-h3 {
	font-size: 16px;
}

.c-header,
.c-footer {
	display: none;
}
