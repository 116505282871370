
/* ==========================================================================
   Font sizes defined as variables
   ========================================================================== */

/* -------------------------------------------------------------------------
Line Heights
--------------------------------------------------------------------------- */

$defaultLineHeight: 1.4;

$mobileLineHeight: 1.5;
$tabletLineHeight: 1.5;
$desktopLineHeight: 1.6;

$mobileTitleLineHeight: 1.2;
$tabletTitleLineHeight: 1.3;

/* -------------------------------------------------------------------------
 Default
 --------------------------------------------------------------------------- */

$defaultFontSize: $defaultPxSizeValue;

$mobileFontSize: 20px;
$tabletFontSize: 22px;
$desktopFontSize: 24px;

$mobileSmallFontSize: 14px;
$desktopSmallFontSize: 16px;

/* -------------------------------------------------------------------------
 Navigation
 --------------------------------------------------------------------------- */

$navigationTitleSize:           24px;
$navigationTitleSize_palm:      30px;
$navigationTitleSize_tablet:    34px;
$navigationTextSize:			16px;
$navigationTextSize_palm:	    22px;
$navigationTextSize_tablet:		26px;


/* -------------------------------------------------------------------------
 Title
 --------------------------------------------------------------------------- */

$titleSize: 				24px;
$titleSize_mobile:			24px;
$titleSize_palm: 			28px;
$titleSize_tablet:	    	34px;
$titleSize_bigtablet:		34px;
$titleSize_desktop: 		50px;
$titleSize_huge:			50px;


/* -------------------------------------------------------------------------
 Page title
 --------------------------------------------------------------------------- */

$pageTitleSize: 			20px;
$pageTitleSize_palm:		26px;


/* -------------------------------------------------------------------------
 Pageheader title
 --------------------------------------------------------------------------- */

$pageHeaderTitleSize:             30px;
$pageHeaderTitleSize_mobile:      30px;
$pageHeaderTitleSize_palm:        36px;
$pageHeaderTitleSize_bigtablet:   40px;
$pageHeaderTitleSize_desktop:     45px;
$pageHeaderTitleSize_huge:        50px;

/* -------------------------------------------------------------------------
 Content
 --------------------------------------------------------------------------- */

$contentTextSizeMobile: 	16px;
$contentTextSizePalm:		16px;
$contentTextSizeTablet: 	18px;
$contentTextSizeDesktop: 	20px;
