
/* ==========================================================================
   Padding Sizes
   ========================================================================== */


/* -------------------------------------------------------------------------
 Wrapper (Container)
 --------------------------------------------------------------------------- */

$wrapper-padding-horizontal: 1em;
$wrapper-padding-vertical: 1.4em;

$wrapper-padding-horizontal_mobile: 30px;
$wrapper-padding-vertical_mobile: 30px;

$wrapper-padding-horizontal_palm: 40px;
$wrapper-padding-vertical_palm: 70px;


$wrapper-padding-horizontal_tablet: 80px;
$wrapper-padding-vertical_tablet: 80px;

$wrapper-padding-vertical_bigtablet: 90px;

$wrapper-padding-horizontal_desktop: 120px;
$wrapper-padding-vertical_desktop: 100px;

$wrapper-padding-vertical_huge: 120px;

$wrapper-padding-horizontal_gigantic: 80px;

/* -------------------------------------------------------------------------
 Siteheader
 --------------------------------------------------------------------------- */

$siteheader-padding-vertical: 14px;
$siteheader-padding-vertical_tablet: 26px;


/* -------------------------------------------------------------------------
 Language Navigation
 --------------------------------------------------------------------------- */

$languagenavigation-padding: .5em;
$languagenavigation-padding_tween: 1em;
$languagenavigation-padding_palm: 1.5em;


/* -------------------------------------------------------------------------
 Navigation Item
 --------------------------------------------------------------------------- */

$navitem-padding-horizontal: 1.4em;
$navitem-padding-vertical: 1.4em;

$navitem-padding-horizontal_tween: 5%;
$navitem-padding-vertical_tween: 5%;

$navitem-padding-horizontal_tablet: 5%;
$navitem-padding-vertical_tablet: 5%;

$navitem-padding-horizontal_desktop: 4em;
$navitem-padding-vertical_desktop: 3em;

/* -------------------------------------------------------------------------
 Main
 --------------------------------------------------------------------------- */

$main-padding-vertical: 1.4em;
$main-padding-vertical_tween: 2em;
$main-padding-vertical_bigtablet: 3em;

/* -------------------------------------------------------------------------
 Pageheader Image
 --------------------------------------------------------------------------- */

$pageheader-image-left: 5em;
$pageheader-image-left_tablet: 6em;
$pageheader-image-left_huge: 6.6em;

$pageheader-wrapper-horizontal_palm: 4em;

$pageheader-wrapper-padding-right_desktop: 6em;

$pageheader-wrapper-padding-left_tablet: 9em;
$pageheader-wrapper-padding-left_desktop: 20%;
$pageheader-wrapper-padding-left_bigger: 26%;
$pageheader-wrapper-padding-left_gigantic: 32%;


/* -------------------------------------------------------------------------
 Abstand klein
 --------------------------------------------------------------------------- */

$verticalPaddingSmall:				18px;
$verticalPaddingSmall_palm:			22px;
$verticalPaddingSmall_desktop:		32px;

/* -------------------------------------------------------------------------
 Abstand: Bild zu Titel
 --------------------------------------------------------------------------- */

$imageToTitlePadding: 1.4em;

/* -------------------------------------------------------------------------
 Column padding
 --------------------------------------------------------------------------- */

$columnPadding_tablet: 5%;
$columnPadding_bigtablet: 5%;
$columnPadding_huge: 4%;

/* -------------------------------------------------------------------------
 Text padding
 --------------------------------------------------------------------------- */

 $textpadding:				1.5em;
 $textpadding_palm:			1.5em;
 $textpadding_tablet:		1.5em;
 $textpadding_desktop:		1.6em;


 /* -------------------------------------------------------------------------
  Context text padding
  --------------------------------------------------------------------------- */

$contexttextpadding:			2em;
$contexttextpadding_palm:		2.4em;
$contexttextpadding_tablet:		3em;
$contexttextpadding_desktop:	4em;

/* -------------------------------------------------------------------------
 Form padding
 --------------------------------------------------------------------------- */

$formlabelpadding: .2em;
$formelementpadding: 1.2em;
$formsectionpadding: 2em;
$formsectionpadding_tablet: 2em;
